import { makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import * as React from 'react'
import { ReactElement } from 'react'
import { InstructionsComponent } from '../../components/InstructionsComponent'
import Layout from '../../components/Layout'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    marginLeft: '10px'
  }
})

export const query = graphql`
  query PLInstrukcjeQuery {
    allContentXlsxPlInstructions {
      nodes {
        course
        name
        url
      }
    }
  }
`

interface Instruction {
  course: string
  name: string
  url: string
}

interface Nodes {
  nodes: Array<Instruction>
}

interface DataSheet {
  allContentXlsxPlInstructions: Nodes
}

interface Props {
  data: DataSheet
}

const Instrukcje = ({
  data: {
    allContentXlsxPlInstructions: { nodes: instructions }
  }
}: Props): ReactElement => {
  const styles = useStyles()
  return (
    <Layout actualTab="Dydaktyka">
      <>
        <p className={styles.title}>Instrukcje</p>
        <InstructionsComponent instructions={instructions} />
      </>
    </Layout>
  )
}

export default Instrukcje
